import loadable from "@loadable/component";
import React, { useRef } from "react";
import ReactModal from "react-modal";
import { RemoveScroll } from "react-remove-scroll";

export const Modal = loadable(
    () =>
        import(
            /* webpackChunkName: "react-modal" */
            "react-modal"
        )
);

interface LazyModalProps extends ReactModal.Props {
    children: React.ReactNode;
}

function LazyModal({ isOpen, children, ...props }: LazyModalProps): React.ReactElement | null {
    const contentRef = useRef<HTMLDivElement>(null);

    return (
        <RemoveScroll enabled={isOpen}>
            <Modal id="lazy-modal" {...props} isOpen={isOpen}>
                <div
                    ref={contentRef}
                    onTouchMove={(event) => {
                        // Avoid the propagation of the scrolling event to the body
                        event.stopPropagation();
                    }}
                >
                    {children}
                </div>
            </Modal>
        </RemoveScroll>
    );
}

export default LazyModal;
