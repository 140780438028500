import clsx from "clsx";
import React from "react";
import CloseButton from "web/react/components/buttons/close-button/close-button";
import LazyModal from "web/react/components/lazy-modal/lazy-modal";
import * as styles from "./generic-modal.module.css";

interface GenericModalProps {
    children: JSX.Element;
    isOpen: boolean;
    onAfterOpen?: () => void;
    onClose: () => void;
    className?: string;
    overlayClassName?: string;
    closeClassName?: string;
    fullscreen?: boolean;
    closable?: boolean;
}

function GenericModal({
    children,
    className,
    closable = true,
    isOpen,
    onAfterOpen,
    onClose,
    overlayClassName,
}: GenericModalProps): null | JSX.Element {
    let appElement: HTMLElement | undefined;
    if (document.body) {
        appElement = document.body.querySelector<HTMLDivElement>(".site-wrapper") || undefined;
    }

    return (
        <LazyModal
            appElement={appElement}
            className={clsx(className, styles.modal)}
            isOpen={isOpen}
            onAfterOpen={(): void => {
                onAfterOpen && onAfterOpen();
            }}
            onRequestClose={onClose}
            overlayClassName={clsx(styles.overlay, overlayClassName)}
            shouldCloseOnOverlayClick={closable}
            shouldCloseOnEsc={closable}
        >
            {closable && (
                <CloseButton
                    className={styles.closeButton}
                    onClick={onClose}
                    useDefaultStyle={false}
                />
            )}
            {children}
        </LazyModal>
    );
}

export default GenericModal;
