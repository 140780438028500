import React, { useCallback, useEffect, useRef, useState } from "react";
import CloseButton from "web/react/components/buttons/close-button/close-button";
import LazyModal from "web/react/components/lazy-modal/lazy-modal";
import LoadingSpinner from "web/react/components/loading-spinner/loading-spinner";
import { useToast } from "web/react/emo/toast/useToast";
import { useDomViewport } from "web/react/hooks/use-dom-viewport/use-dom-viewport";
import { useUrlData } from "web/react/hooks/use-url-data/use-url-data";
import analytics from "web/script/analytics/analytics";
import { gettext } from "web/script/modules/django-i18n";
import environment from "web/script/modules/environment";
import globals from "web/script/modules/globals";
import history, { BrowserHistoryEvent } from "web/script/utils/history";
import storage from "web/script/utils/storage";
import * as styles from "./url-overlay.css";

interface ProductOverlayProps {
    children: React.ReactNode;
    load: (controller: AbortController) => Promise<any>;
    getOverlayDecision: (
        urlData: BrowserHistoryEvent | null,
        isOverlayOpen: boolean
    ) => "open" | "close" | void;
    isLoading: boolean;
    parentRef?: React.RefObject<HTMLElement>;
    onFocus?: () => void;
}

export function UrlOverlay({
    children,
    load,
    getOverlayDecision,
    isLoading,
    parentRef,
    onFocus,
}: ProductOverlayProps): React.ReactElement | null {
    const [isOpen, setIsOpen] = useState(false);
    const [isFocused, setIsFocused] = useState(false);
    const [pagePositionOnOpen, setPagePositionOnOpen] = useState(0);
    const pageUnderOverlayData = useRef({
        url: "",
        pageViewId: "",
        pageType: environment.get("pageType"),
        pageSubType: environment.get("pageSubType"),
    });
    const controller = useRef<AbortController>();
    // Keep track of when the data fetch operation finishes for the current urlData
    // so we can avoid reruning the useEffect hook for the overlay decision below
    const dataFetchCompleted = useRef(false);

    const urlData = useUrlData();
    const overlayDecision = getOverlayDecision(urlData, isOpen);
    const { isMobileViewport, isTabletViewport } = useDomViewport();
    const toast = useToast();

    // Set the window position
    const handlePagePosition = useCallback(
        (position: number, setState = false): void => {
            if (isMobileViewport || isTabletViewport) {
                setState && setPagePositionOnOpen(globals.window.scrollY);
                globals.window.scrollTo({ top: position, behavior: "auto" });
            }
        },
        [isMobileViewport, isTabletViewport]
    );

    // Close the overlay
    const close = useCallback((): void => {
        const reason = controller.current?.signal.reason;

        // Reset back the controller so we can trigger new overlays later
        controller.current = undefined;

        // We will either have no reason (there is no aborted request)
        // or the abort reason is to close the overlay
        // (the user goes back to the feed page either by clicking the back button
        // or by clicking the X / ESC / outside the overlay)
        if (reason === "previousOverlay") {
            return;
        }

        document.body.classList.remove("no-scroll");
        handlePagePosition(pagePositionOnOpen);

        // Set back the page type and subtype so it can be used
        // in the analytics
        // We need to take this from the oldURL a.k.a the URL we are coming from
        environment.set({
            pageType: pageUnderOverlayData.current.pageType || window.Lyst?.data?.pageType,
            pageSubType: pageUnderOverlayData.current.pageSubType || window.Lyst?.data?.pageSubType,
        });

        pageUnderOverlayData.current = {
            url: urlData?.newURL.href || "",
            pageViewId: urlData?.state?.pageViewId || "",
            pageType: environment.get("pageType"),
            pageSubType: environment.get("pageSubType"),
        };

        setIsOpen(false);
    }, [urlData, pagePositionOnOpen, handlePagePosition]);

    // Open the modal based on the urlData
    // and also fetch the product data to display
    // in case it fails - close the modal and show an error message
    const open = useCallback((): void => {
        if (!urlData) {
            return;
        }

        controller.current = new AbortController();
        document.body.classList.add("no-scroll");
        setIsOpen(true);
        handlePagePosition(0, true);
        load(controller.current)
            .then(() => {
                dataFetchCompleted.current = true;
            })
            .catch((error) => {
                close();

                // If we aborted the request
                // then don't show the Error message
                // since it is intentional
                if (error.name !== "AbortError") {
                    toast({ message: gettext("error.500.title") });
                }
            });
    }, [urlData, handlePagePosition, load, close, toast]);

    // Since the decision of opening / closing overlay
    // is determined by the urlData (browser history)
    // when we hit the Close button / ESC or any other close action on the modal
    // we are just going to trigger a history event and let the
    // useOverlayDecision handle the opening / closing of the overlay
    function triggerClose(): void {
        analytics.setPageViewId(pageUnderOverlayData.current.pageViewId);
        analytics.setReferrer(
            pageUnderOverlayData.current.url,
            pageUnderOverlayData.current.pageViewId
        );

        const initialPageURL = new URL(
            storage.get("initialPageURL", globals.window.location.href, true)
        );
        const url = pageUnderOverlayData.current.url
            ? new URL(pageUnderOverlayData.current.url)
            : // in case the initial URL was hash URL
              // we will have the overlay opened but nothing in the urlData
              // then we need to use the URL from the storage
              new URL(initialPageURL.href);

        history.pushState(new URL(url.href.replace(url.hash, "")), {
            saveScrollPosition: false,
            data: {
                pageViewId: pageUnderOverlayData.current.pageViewId,
                overlay: undefined, // reset this value
            },
        });
    }

    useEffect(() => {
        // This setting prevents default behaviour of scrolling the document when a popstate event occurs
        // We want this so that the viewport sits at the top of the page when transitioning from overlays to product pages.
        // Not supported by IE
        if (globals.window.history?.scrollRestoration) {
            globals.window.history.scrollRestoration = "manual";
        }

        return (): void => {
            if (globals.window.history?.scrollRestoration) {
                globals.window.history.scrollRestoration = "auto";
            }
        };
    });

    // If we have a overlayDecision based on the URL data
    // we want to reset the dataFetchCompleted
    // and abort the current request
    // otherwise the URL changes are not related to product URL
    // therefore we want to store the URL state and page data
    useEffect(() => {
        if (overlayDecision) {
            dataFetchCompleted.current = false;
            controller.current?.abort(
                overlayDecision === "open" ? "previousOverlay" : "closeOverlay"
            );
        } else {
            pageUnderOverlayData.current = {
                url: urlData?.newURL.href || "",
                pageViewId: urlData?.state?.pageViewId || "",
                pageType: environment.get("pageType"),
                pageSubType: environment.get("pageSubType"),
            };
        }
    }, [overlayDecision, urlData]);

    // Handle the overlay decision based on the urlData
    useEffect(() => {
        if (!isLoading && !dataFetchCompleted.current) {
            switch (overlayDecision) {
                case "open": {
                    open();
                    break;
                }
                case "close": {
                    close();
                    break;
                }
                default: {
                    break;
                }
            }
        }
    }, [isLoading, dataFetchCompleted, overlayDecision, urlData, open, close]);

    // Determines if the browser tab is focused or not
    useEffect(() => {
        if (!isOpen) {
            return;
        }

        function listener(): void {
            setIsFocused(globals.document.visibilityState === "visible");
        }

        globals.document.addEventListener("visibilitychange", listener);

        return () => {
            globals.document.removeEventListener("visibilitychange", listener);
        };
    }, [isOpen]);

    // Run the onFocus callback after the data load is completed
    useEffect(() => {
        if (isLoading || !dataFetchCompleted.current || !isFocused) {
            return;
        }

        onFocus?.();
    }, [isLoading, isFocused, onFocus]);

    return (
        <div data-testid="overlay-container">
            <div id="product-overlay">
                <LazyModal
                    appElement={
                        document.body?.querySelector<HTMLDivElement>(".site-wrapper") || undefined
                    }
                    parentSelector={parentRef && (() => parentRef.current as HTMLElement)}
                    className={styles.content}
                    isOpen={isOpen}
                    onRequestClose={triggerClose}
                    overlayClassName={styles.background}
                    shouldCloseOnOverlayClick
                    shouldCloseOnEsc
                >
                    <div className={styles.closeContainer} data-testid="overlay-close-button">
                        <CloseButton
                            useDefaultStyle={false}
                            className={styles.closeButton}
                            onClick={triggerClose}
                        />
                    </div>
                    <div className={styles.container}>
                        {isLoading ? (
                            <div data-testid="loading-spinner">
                                <LoadingSpinner />
                            </div>
                        ) : (
                            children
                        )}
                    </div>
                </LazyModal>
            </div>
        </div>
    );
}
