import clsx from "clsx";
import React, { useState } from "react";
import CloseButton from "web/react/components/buttons/close-button/close-button";
import LazyModal from "web/react/components/lazy-modal/lazy-modal";
import useIsomorphicLayoutEffect from "web/react/hooks/use-isomorphic-layout-effect/use-isomorphic-layout-effect";
import styles from "./generic-sticky-modal.module.css";

type StickyModalStyleTypes = "fullscreen" | "sheet";

export interface GenericStickyModalProps {
    children: JSX.Element;
    isOpen: boolean;
    onAfterOpen?: () => void;
    onClose: () => void;
    className?: string;
    closeClassName?: string;
    overlayClassName?: string;
    closable?: boolean;
    styleType?: StickyModalStyleTypes;
}

function GenericStickyModal({
    children,
    isOpen,
    onAfterOpen,
    onClose,
    className,
    closeClassName,
    overlayClassName,
    closable = true,
    styleType = "sheet",
}: GenericStickyModalProps): null | JSX.Element {
    const [animate, setAnimate] = useState(false);
    const [animateOut, setAnimateOut] = useState(false);

    useIsomorphicLayoutEffect(() => {
        if (!isOpen) {
            setAnimate(false);
            setAnimateOut(false);
        }
    }, [isOpen]);

    let appElement: HTMLElement | undefined;
    if (document.body) {
        appElement = document.body.querySelector<HTMLDivElement>(".site-wrapper") || undefined;
    }

    function closeModal(): void {
        setAnimateOut(true);
        setTimeout(function () {
            onClose();
        }, 200);
    }

    return (
        <LazyModal
            appElement={appElement}
            className={clsx(styles.stickyModal, className, {
                [styles.animateIn]: animate,
                [styles.animateOut]: animateOut,
                [styles.fullscreen]: styleType === "fullscreen",
            })}
            isOpen={isOpen}
            onAfterOpen={(): void => {
                onAfterOpen && onAfterOpen();
                setTimeout(function () {
                    setAnimate(true);
                }, 100);
            }}
            onRequestClose={closeModal}
            overlayClassName={clsx(styles.overlay, overlayClassName)}
            shouldCloseOnOverlayClick={closable}
            shouldCloseOnEsc={closable}
        >
            {closable && (
                <CloseButton
                    onClick={closeModal}
                    className={clsx(styles.closeButton, closeClassName)}
                />
            )}
            {children}
        </LazyModal>
    );
}

export default GenericStickyModal;
